Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.contentTypeApplicationJson = 'application/json'
exports.getBrandsEndPoint = "bx_block_website_builder/designs/get_design";
exports.getStoreDetails = 'bx_block_store_details/public/store_profile'
exports.searchServiceListAPIEndPoint = 'bx_block_catalogue/catalogues/search_service';
exports.getMethod = 'GET'
exports.emailRequired = 'Email is required'
exports.invalidEmail = 'Invalid email address'
exports.bookingIdRequired = 'Booking ID is required'
exports.PreviewMode = 'Preview Mode'
exports.helperText = 'Use this preview to experience the website and place test orders'
exports.backToAdmin = 'Back to Admin'
exports.getManageBookingEndPoint = 'bx_block_appointment_management/appointments/manage_booking'
exports.manageBooking = "Manage Booking";
exports.navigationManageBooking = "webcustomer_navigation_manage_booking";
exports.proceedManageBookingForm = "webcustomer_proceed_manage_booking_form";
exports.errorInStorageDetails = "_webcustomer_error_occurred_in_storage_details";
exports.getLocationEndPoint = "bx_block_settings/common_settings";
exports.themeName={
nexus:"Nexus",
radiance:"Radiance"
}
exports.numerRegex= /^\s*\d+\s*$/;
exports.invalidbookingId="Booking ID should be numerics"

// Customizable Area End
