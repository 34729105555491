// Customizable Area Start
import React from "react";

export interface Props {
    text: string;
    disountPrice: string;
    time: string | number | object;
    hasDiscount: boolean;
    isPrmocode?: boolean;
    fontColor?: string;
    fontFamily?: string;
    serviceType?: string
}

interface S {
    currency: string;
}

export interface StoreData {
    storeData: StoreProfileData
}

interface StoreProfileData {
    name: string;
    country_id: number;
    currency_id: number;
    currency_symbol: string;
    country_code: string;
    country_name: string;
    service_provider_available: boolean;
    buildcard_name: string;
    email: string,
    sender_email: string,
    customer_support_email: string,
    address_state_id: number,
    city_id: number,
    zipcode: string,
    address_line_1: string,
    address_line_2: string,
    is_whatsapp_integration: true,
    whatsapp_number: string,
    whatsapp_message: string,
    gst_number: string,
    phone_number: string,
};

interface SS {
    id: any;
}

export default class Currency extends React.Component<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        const storeDetails = this.getStoreDetails();
        this.state = {
            currency: storeDetails?.currency_symbol || "₹"
        };
    }


    async componentDidMount() {
        window.addEventListener("store_details", this.localStorageListener);
    }

    async componentWillUnmount() {
        window.removeEventListener("store_details", this.localStorageListener);
    }

    localStorageListener = () => {
        const storeDetails = this.getStoreDetails();
        if (storeDetails && storeDetails !== "undefined") {
            this.setState({ currency: storeDetails?.currency_symbol || "₹" })
        }
    }

    getStoreDetails = () => {
        const storeDetailsString = localStorage.getItem("storeDetails");
        if (storeDetailsString && storeDetailsString !== "undefined") {
            return JSON.parse(storeDetailsString)
        }
        else null
    }

    render() {
        // "\u200E" for left-to-right conversion for د.إ
        const fullText = "\u200E" + (this.state.currency || "₹") + "\u200E" + (this.props.text || "");
        const fullText1 = "\u200E" + (this.state.currency || "₹") + "\u200E" + (this.props.disountPrice || "");
        const promocodeApplied = "\u200E" + (this.state.currency || "₹") + "\u200E" + (this.props.text || "");

        return <>
            {this.props.serviceType === "Extra" ?
                <>
                    {this.props.text && <span style={{ ...webStyle.heading, color: this.props.fontColor, fontFamily: this.props.fontFamily }} className="price">{fullText}</span>}
                    {this.props.hasDiscount && <span style={{ ...webStyle.discount, color: this.props.fontColor, fontFamily: this.props.fontFamily }} className="discountedPrice">{fullText1}</span>}
                </> :
                <>
                    {this.props.isPrmocode ? <span style={webStyle.coupan}>-{promocodeApplied}</span>
                        :
                        <>
                            {this.props.text && <span style={{ ...webStyle.heading, color: this.props.fontColor, fontFamily: this.props.fontFamily }} className="price">{fullText}</span>}
                            {this.props.hasDiscount && <span style={{ ...webStyle.discount, color: this.props.fontColor, fontFamily: this.props.fontFamily }} className="discountedPrice">{fullText1}</span>}
                            <span style={{ ...webStyle.heading, color: this.props.fontColor, fontFamily: this.props.fontFamily }} className="duration">{this.props.time}</span>
                        </>
                    }
                </>
            }

        </>
    }
}

const webStyle = {
    discount: {
        color: "var(--website-builder-secondary-color)",
        textDecoration: "line-through",
        fontFamily: "var(--website-builder-body-font)",
    },
    coupan: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 700,
        fontFamily: "var(--website-builder-body-font)",
        color: "var(--website-builder-secondary-color)"
    },
    heading: {
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",
        lineHeight: "24px",
    },
    extraPriceText:{
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "18px",
        color: "var(--website-builder-secondary-color)",
        fontFamily: "var(--website-builder-body-font)",

    },
    extarDiscount: {
        color: "var(--website-builder-secondary-color)",
        textDecoration: "line-through",
        fontFamily: "var(--website-builder-body-font)",
        lineHeight: "12px",
        fontWeight: 500,
        fontSize:"8px"
    },
}

export const saveStoreDetails = (storeDetails: StoreProfileData) => {
    window.localStorage.setItem("storeDetails", JSON.stringify(storeDetails));
    window.dispatchEvent(new Event("store_details"));
}
// Customizable Area End