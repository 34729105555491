Object.defineProperty(exports, "__esModule", {
  value: true
});
// Customizable Area Start
exports.postAPiMethod = 'POST';
exports.putAPiMethod = 'PUT';
exports.getApiMethod = 'GET';
exports.patchApiMethod = 'PATCH';
exports.deleteApiMethod = 'DELETE';
exports.apiContentType = "application/json"; 
exports.storeProfileEndPointAPI = "/bx_block_store_details/store_profile";
exports.noImageAvailableUrl = "https://placehold.co/100x100/f6f6f6/d1d6db?text=No+Image%5CnAvailable";

exports.createContentWebsiteBuilderEndPoint = "bx_block_website_builder/designs";
exports.getServicesEndPointAPI="/bx_block_website_builder/designs/service_list";
exports.getStaffEndPointAPI ="bx_block_website_builder/designs/service_provider_list"
exports.defaultColorsApiEndPoint = "/bx_block_website_builder/colour_selections";
exports.headerTemplateApiEndPoint = "bx_block_website_builder/designs/get_template_details?template_type";
exports.getStoreDetailAPI = 'bx_block_store_details/store_profile';
exports.textNewDesktop = "Desktop";
exports.textNewMobile = "Mobile";
exports.texttFooterSection = "Copyright © 2021 Studio Store. All rights reserved Powered by Builder ai.";
exports.textAreaWillDisplay = "This area will display an email link for your customers along with the Phone number and WhatsApp number added on Store Details."
exports.textFooterCopyRight = "Copyright © 2021 Studio Store. All rights reserved";
exports.textFooterBuilderAi = "Builder.ai";
exports.textFooterPowered = "Powered by"
exports.textUrlNotValid = "Oops! It seems like the provided URL is not valid.";
exports.textHeaderBranding ="Branding";
exports.textPreview ="Quick homepage preview";
exports.textNotValid = "Copyright must be at maximum of 150 characters";
exports.textRequired = "Copyright field is required";
exports.desktopBanner = "Desktop Banner";
exports.mobileBanner = "Mobile Banner";
exports.mobileCropperText = "Crop your image to appear for";
exports.textMobileDevice = "mobile devices";
exports.textDesktopDevice = "Desktop";
exports.selectionMobileText = "Upload images from your device or from Builder Image Catalogue";
exports.textBannerImageSelection = "Banner Image Selection";
exports.textSelectionPixel = "Upload a banner image that is at least 2880X960 pixels for optimal results. File Size Max 20MB";
exports.textSelectionPixelMobile = "Upload a banner image that is at least 780*800 pixels for optimal results. File Size Max 20MB";
exports.textUploadButton = "Upload from device";
exports.textChooseCatalogue = "Choose from Image Catalogue";
exports.textModalRemoved = "The section selected will be removed from this Home page on your site."
exports.textUploadLogo = "Upload Logo";
exports.textUploadFavicon = "Upload Favicon";
exports.textUploadLogoModalText = "Click to Upload";
exports.textUploadLogoModalMessage = "Upload a Logo image that is at least 600X142 pixels for optimal results. File Size Max 20MB. Supported Formats PNG, JPG, JPEG.";
exports.textUploadFaviconModalMessage = "Upload a Favicon image that is at least 32 X 32 pixels for optimal results. File Size Max 20MB. Supported Formats PNG, JPG, JPEG.";
exports.addLogoText = "Add Logo";
exports.addFaviconText = "Add Favicon";
exports.HeaderNavigationSettingsMessage = "Click on edit icon to rename navigation sections";
exports.FaviconTooltipText = "Favicon is the image that appears on the tab of the browser window for the website";
exports.textUrlRequired = "URL is required";
exports.SocialTooltipText = "Leave this field blank if you prefer not to display this information";
exports.getImageCatalougeAPIEndpoint = "bx_block_website_builder/designs/search_images?";
exports.NavigationItemText = "Navigation item ";
exports.manageBooking = "Manage Bookings";
exports.serviceDisplay = "Service Display";
exports.testimonial = "Testimonial";
exports.location = "Location";
exports.displayTestimonials = "Display Testimonials";
exports.displayLocation = "Display Location";
exports.sectionName = "Section Name";
exports.service = "Service";
exports.textTestimonial = "TESTIMONIALS";
exports.getCustomerFeedbackEndPoint = "bx_block_customer_feedback/customer_feedbacks";
exports.getLocationEndPoint = "bx_block_settings/common_settings";
exports.getStaticPageApiEndpoint= "bx_block_static_pages/static_pages";
exports.address = "Address";
exports.openingHours = "Opening hours";
exports.showHeaderNameEvent = "Show_store_name" 
exports.updateLogoEvent = "update_logo" 
exports.updateLogoSuccessEvent = "update_logo_success" 
exports.updateFavEvent = "add_favicon"
exports.updateFavSuccessEvent = "add_favicon_success" 
exports.updateLogoSuccessEvent = "update_logo_success" 
exports.address = "Shop address";
exports.openingTime = "Opening time";
exports.helpCenter = "Help Center";
exports.aboutUs = "About Us";
exports.faqs = "FAQs";
exports.privacyPolicy = "Privacy Policy";
exports.term = "Terms of Service";
exports.contactUs = "Contact Us";
exports.email = "Email";
exports.sendMessage = "Send a message";
exports.contact = "(+44) 1234-567-89";
exports.bannerPosition = "Banner Position";
exports.selectBannerPosition = "Select Banner Position";
exports.left = "Left";
exports.right = "Right";
exports.bannerBackColor = "Banner Background Color";
exports.bannerTextMobile = "Banner Text - Mobile";
exports.download = "Download App";
exports.followUs = "Follow Us On";
exports.bannerText = "Banner Text - Desktop";
exports.bannerImage = "Banner Image";
exports.selectFont = "Select Font";
exports.fontName = "Font Name";
exports.customerName = "Floyd Miles";
exports.textBookNow = "Book Now";
exports.viewAll = "View All";
exports.imageValidationText = "Image URL is required";
exports.apply = "Apply";
exports.customHeader = "Customize Header";
exports.customBody = "Pick a style that sets the tone for your site."
exports.testimonilaText = "I was blown away by the service! From the moment I walked in, the staff made me feel welcomed and comfortable. My stylist listened attentively to my preferences and offered valuable suggestions"
exports.menuItems = [
  {
    id: 1,
    label: '1st Menu Item ',
    options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
    selectedOption: 'Select',
  },
  {
    id: 2,
    label: '2nd Menu Item ',
    options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
    selectedOption: 'Select',
  },
  {
    id: 3,
    label: '3rd Menu Item ',
    options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
    selectedOption: 'Select',
  },
  {
    id: 4,
    label: '4th Menu Item ',
    options: ['Shoe', 'Blazers', 'Casual Shirts', 'Casual Shoes', 'Flip Flops', 'Foot wear', 'Forage cap', 'Forage cap'],
    selectedOption: 'Select',
  },
]
exports.NavigationItemOneText = "Navigation item 1 (Service)";
exports.NavigationItemTwoText = "Navigation item 2 (Service provider)";
exports.NavigationItemThreeText = "Navigation item 3 (Gallery) ";
exports.fontsList = [
  "Roboto",
  "Inter",
  "Open Sans",
  "Montserrat",
  "Source Sans Pro",
  "Noto Sans",
  "Poppins",
  "Raleway",
  "Oxygen",
  `${"Lato"}`,
]
exports.textAlignmentList = [
  "Left",
  "Center",
  "Right",
]
exports.textPositionList = [
  "Top",
  "Center",
  "Bottom",
]
exports.desktopTextPositionList = [
  "Top Left",
  "Top Right",
  "Bottom Left",
  "Bottom Right",
  "Center",
  "Mid Left",
  "Mid Right",
]
exports.cancel = "Cancel";
exports.uploadNew = "Upload from device";
exports.save = "Save";
exports.editLogo="Edit Logo"
exports.editFavicon="Edit Favicon";
exports.textAll = "All";
exports.textYourImages = "Your Images";
exports.textBuilderLibrary = "Builder Library";
exports.allText = "all";
exports.yourImagesText = "your_images";
exports.builderLibraryText = "banner_lib";
exports.textCategory = "Category : ";
exports.textSubCategory = "Sub-category : ";
exports.uploadFileText = "Upload from Device";
exports.uploadFileTextSummary = "Allows to upload image from your device";
exports.buiderLibraryText = "Browse Library";
exports.buiderLibraryTextSummary = "Select from our library or your uploaded images.";
exports.noResultText = "No Results Found!";
exports.noResultTextSummary = "We couldn't find any matches for your search criteria. Please search again.";
exports.viewAllText = "View all";
exports.services = "Services";
exports.serviceProviders = "Service Providers";
exports.gallery = "Gallery";
exports.manageBooking = "Manage Booking";
exports.chooseHeaderText = "Choose header design";
exports.addSection = "Add Section";
exports.themeName={
  nexus:"Nexus",
  radiance:"Radiance"
  }
exports.showMoreText="Show More"
// Customizable Area End